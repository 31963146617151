import React, { FunctionComponent, useContext } from 'react';
import FormularElement from './formBuilder/formularElement';
import { ReactComponent as ErrorImage } from '../assets/img/error/undraw_cancel_re_ctke.svg';
import ElementParser from './formBuilder/elementParser';
import { GlobalErrorStateContext } from './globalErrorContext';
import { Button, Typography } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { URIParameterContext } from './contexte/URIParameterContext';
import { handleReturn } from './genericErrorPage';

interface CustomErrorPageProps {
  staticPagesConfig?: FormularElement[];
}

const CustomErrorPage: FunctionComponent<CustomErrorPageProps> = ({
  staticPagesConfig,
}) => {
  const { error } = useContext(GlobalErrorStateContext);
  const { URIParameter } = useContext(URIParameterContext);
  const { project } = useParams();
  const location = useLocation();

  const errorPage: FormularElement | undefined = staticPagesConfig?.find(
    (page: FormularElement) => page.route === '/error'
  );

  const showFallbackErrorImage = errorPage?.showFallbackErrorImage ?? true;
  const statusCode = location.state?.statusCode.toString();
  const errorCode = error?.status?.toString() || statusCode?.toString();

  // fallback error messages
  const FALLBACK_MESSAGE_404 = 'Diese Seite existiert nicht.';
  const FALLBACK_MESSAGE =
    'Irgendetwas lief schief. Bitte kehre zur Antragsstrecke zurück und versuche es erneut.';

  const getErrorMessage = () => {
    const errorEntryValue = errorPage?.errorsMap
      ?.find((err) => err.key === errorCode)
      ?.value?.trim();
    return (
      errorEntryValue ||
      (typeof error?.text === 'string' && error?.text?.trim()) ||
      (!errorCode || errorCode === '404'
        ? FALLBACK_MESSAGE_404
        : FALLBACK_MESSAGE)
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        minHeight: '100vh',
        padding: '20px',
        boxSizing: 'border-box',
      }}
    >
      {showFallbackErrorImage && <ErrorImage />}
      {error?.header && <Typography variant="h3">{error.header}</Typography>}
      {errorPage?.elements && errorPage.elements.length > 0 && (
        <ElementParser elements={errorPage.elements} />
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          gap: 4,
        }}
      >
        <Typography variant="subtitle1">{getErrorMessage()}</Typography>
      </div>
      <Button
        onClick={() => handleReturn(project, URIParameter)}
        size="large"
        color="primary"
        variant="contained"
        sx={{ marginTop: 4 }}
      >
        Jetzt neu starten
      </Button>
    </div>
  );
};

export default CustomErrorPage;
